import { render, staticRenderFns } from "./RectangleBgCardBlock.vue?vue&type=template&id=11123f0e&"
import script from "./RectangleBgCardBlock.vue?vue&type=script&lang=js&"
export * from "./RectangleBgCardBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RectangleBgCard: require('/server/itg-custom/production/front/components/RectangleBgCard/RectangleBgCard.vue').default,CardsGrid: require('/server/itg-custom/production/front/components/CardsGrid.vue').default})
