
export default {
  name: 'CardsGrid',
  props: {
    size: {
      type: String,
      default: '1x1x1x'
    }
  }
}
